<template>
  <div>
    <div class="page-title">
      <h3>My projects</h3>
    </div>
    <section>
      <table v-if="UProject.length">
        <thead>
        <tr>
          <th>Name</th>
          <th>Create</th>
          <th>Delete</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(items,index) in UProject" :key="'project'+index">
          <td><router-link :to="'/gen?d='+items.date+'&n='+items.name"><button class="btn-small btn">{{items.name}}</button></router-link></td>
          <td>{{items.date | date('date')}}</td>
          <td>
            <button @click="removeProject(index)" class="btn-small btn">
              <i  class="material-icons">delete</i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else>You have no projects yet. Set the name and create your first</div>
    </section>
    <form style="margin-top: 20px">
    <div class="input-field"><input v-model="PName" id="description" type="text"><label for="description" class="">Name</label></div>
    <button :disabled="!PName.length" @click.prevent="addProject()" class="btn waves-effect waves-light" type="submit"> Create new
      <i class="material-icons right">send</i>
    </button>
    </form>
  </div>
</template>

<script>
// import firebase from 'firebase'

export default {
  data: () => ({
    PName: '',
    UProject: [],
    initiate: true

  }),
  watch: {
    UProject: {
      deep: true,
      async handler (value) {
        if (!this.initiate) {
          if (value.length) {
            localStorage.project = JSON.stringify(value)
            if (JSON.stringify(value).length) {
              await this.$store.dispatch('newProject', { json: JSON.stringify(value), date: value[value.length - 1].date })
            }
          }
        } else {
          this.initiate = false
        }
      }
    }
  },
  methods: {
    addProject () {
      const date = Date.now()
      this.UProject.push({
        date: date,
        ldate: date,
        name: this.PName
      })
      this.PName = ''
      this.applySetupForNewProject(date)
    },
    removeProject (index) {
      this.UProject.splice(index, 1)
    },
    async applySetupForNewProject (date) {
      await this.$store.dispatch('getDirectValue', 'preset')
      const preset = JSON.parse(this.$store.getters.data)
      const modules = []
      if (preset.collection !== 'None') {
        await this.$store.dispatch('getTheModulesLibrary')
        await this.$store.dispatch('getDirectValue', '/collections/' + preset.collection + '/keys')
        const collectionArray = JSON.parse(this.$store.getters.data)
        const dbModules = JSON.parse(localStorage.modulesStorage).filter(m => collectionArray.includes(m.indexer))
        dbModules.forEach(m => {
          modules.push({ indexer: m.indexer, selected: false })
        })
        preset.modules = JSON.stringify(modules)
      }
      const palettes = []
      if (preset.palettes !== 'None') {
        await this.$store.dispatch('getThePalettesLibrary')
        const dbPalettes = this.$store.getters.library.palettes
        if (preset.palettes === 'All') {
          dbPalettes.forEach(p => {
            palettes.push({ colors: p.colors, indexer: p.indexer, selected: false })
          })
        } else if (preset.palettes === 'Favorite') {
          await this.$store.dispatch('getArrayFromTableJson', { path: 'favoritePallets', user: false })
          const favorites = this.$store.getters.data
          dbPalettes.filter(pl => favorites.includes(pl.indexer)).forEach(p => {
            palettes.push({ colors: p.colors, indexer: p.indexer, selected: false })
          })
          preset.palettes = JSON.stringify(palettes)
        }
      }
      await this.$store.dispatch('setPersonalDataToTable', { data: preset, type: 'resources/' + date })
    }
  },
  async mounted () {
    if (localStorage.count && localStorage.count.length > 0) {
      localStorage.removeItem('count')
      localStorage.removeItem('extensionType')
      localStorage.removeItem('grid')
      localStorage.removeItem('modules')
      localStorage.removeItem('palettes')
      localStorage.removeItem('settingsAggregator')
      localStorage.removeItem('staticSettingsAggregator')
      localStorage.removeItem('mutation')
      localStorage.removeItem('spaces')
      localStorage.removeItem('background')
    }
    await this.$store.dispatch('fetchProjects')
    localStorage.project = this.$store.getters.projects ? this.$store.getters.projects : []
    this.UProject = localStorage.project ? JSON.parse(localStorage.project) : []
  }
}
</script>
